.dark-orange-bg-mesh {
  background-color: #f7974f;
  background-image: radial-gradient(
      58.75% 85.52% at 50% 50%,
      rgba(118, 175, 68, 0) 0%,
      #f7974f 100%
    ),
    url('../../assets/globals/bg_mesh.svg');
  background-size: cover, cover;
  background-position: center, center;
}
.orange-bg-mesh {
  background-color: #f3e8ca;
  background-image: radial-gradient(
      58.75% 85.52% at 50% 50%,
      rgba(118, 175, 68, 0) 0%,
      #f3e8ca 100%
    ),
    url('../../assets/globals/bg_mesh.svg');
  background-size: cover, cover;
  background-position: center, center;
}

@media screen and (min-width: 1280px) {
  .carousel-wrapper {
    display: grid;
    grid-template-rows: 1fr !important;
    grid-template-columns: minmax(0, 1fr) 180px minmax(200px, 1fr);
  }
}
@media screen and (min-width: 1440px) {
}
